<template>
  <q-dialog
    v-model="isOpen"
    persistent
    maximized
  >
    <q-card>
      <q-card-section class="row items-center q-py-sm">
        <div class="text-h6">
          {{ $t('Select product') }}
        </div>

        <q-space />

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>

      <q-card-section>
        <q-card
          v-for="offer in offers"
          :key="offer.id"
          class="q-mb-sm"
          @click="handleOk(offer)"
        >
          <product-object
            :data="offer"
            :use-items="false"
          />
        </q-card>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="negative"
          :label="$t('Cancel')"
          no-caps
          @click="handleClose"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'OffersModal',
  data () {
    return {
      isOpen: false,
      offers: []
    }
  },
  methods: {
    resolve () {},
    reject () {},
    show (offers = []) {
      this.offers = offers
      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClose () {
      this.isOpen = false
      this.resolve(null)
    },
    handleOk (offer) {
      this.isOpen = false
      this.resolve(offer)
    }
  }
}
</script>
